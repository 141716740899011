import { API_POST, API_GET } from "./APIMethods";
import { Blogtype } from "./actionType";
import { BlogUrl } from "./actionURL";

export const changefield = obj => dispatch =>
dispatch({ 
  type: Blogtype.CHANGE_BLOG_FORM,
  payload: obj
});

export const createblog = data => {
  const url = `${BlogUrl.CREATE_BLOG}`;
  return async dispatch => {
    try {
      const result = await API_POST(url, data);
      console.log(result, 'result')
      dispatch({ 
        type: Blogtype.CREATE_BLOG,
        payload: result
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchallblogs = data => {
  const url = `${BlogUrl.FETCH_BLOGS}`;
  return async dispatch => {
    try {
      const result = await API_GET(url);
      dispatch({ 
        type: Blogtype.FETCH_BLOGS,
        payload: result.allblogs
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteblog = id => {
  const url = `${BlogUrl.DELETE_BLOGS}/${id}`;
  return async dispatch => {
    try {
      const result = await API_POST(url);
      console.log(result, 'result')
      dispatch({ 
        type: Blogtype.DELETE_BLOGS,
        payload: result.result
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };
};

export const editblog = id => dispatch =>
  dispatch({
    type: Blogtype.SET_BLOG_FORM,
    payload: id
  });

  export const updateblog = (id, obj) => {
    const url = `${BlogUrl.UPDATE_BLOG}/${id}`;
    return async dispatch => {
      try {
        const result = await API_POST(url, obj);
        console.log(result, "result");
        dispatch({
          type: Blogtype.UPDATE_BLOG,
          payload: { result: result.result, updateblogid: id }
        });
      } catch (error) {
        console.log(error);
      }
    };
  };

  export const emptyblogform = obj => dispatch =>
  dispatch({
    type: Blogtype.EMPTY_BLOG_FORM,
    payload: obj
  });

  export const readmoreblog = obj => dispatch =>
  dispatch({
    type: Blogtype.READMORE_BLOG,
    payload: obj
  });

  export const knowmoreblog = data => {
    const url = `${BlogUrl.FETCH_BLOG_BY_CATEGORY}`;
    return async dispatch => {
      try {
        const result = await API_POST(url, data);
        console.log(result, 'result')
        dispatch({ 
          type: Blogtype.FETCH_BLOG_BY_CATEGORY,
          payload: result.result
        });
        return result;
      } catch (error) {
        console.log(error);
      }
    };
  };