import React, { Component } from "react";
import { Link } from "react-router";
import { Row, Col, BackTop } from "antd";
import "./Footer.scss";

const style = {
  height: 40,
  width: 40,
  lineHeight: "40px",
  borderRadius: 4,
  backgroundColor: "#1088e9",
  color: "#fff",
  textAlign: "center",
  fontSize: 14,
  zIndex: 99,
  right: 36,
};
class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="footer">
          <div className="container">
            <Row justify="center" gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 3 }}
              >
                <div className="links">
                  <h4>Helpful Links</h4>
                  <ul>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/career">Career</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/stakeholders">Stakeholders</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              {/* <!--Helpful Links ends--> */}

              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 4 }}
              >
                <div className="links">
                  <h4>Informations</h4>
                  <ul>
                    <li>
                      <Link to="/why-choose-us">Why Choose Us</Link>
                    </li>
                    <li>
                      <Link to="/our-offerings">Our offerings</Link>
                    </li>
                    <li>
                      <Link to="/team">Team</Link>
                    </li>
                    <li>
                      <Link to="#">Partners</Link>
                    </li>
                    <li>
                      <Link to="#">Testimonials</Link>
                    </li>
                    <li>
                      <Link to="#">Customers</Link>
                    </li>
                    {/* <li><Link to="/faq">FAQ</Link></li> */}
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
              >
                <div className="links">
                  <h4>Workday Services</h4>
                  <ul>
                    <li>
                      <Link to="/advisory-services">Advisory Services</Link>
                    </li>
                    <li>
                      <Link to="/managed-services-and-new-functions-deployment">
                        Managed Services and New Functions Deployment
                      </Link>
                    </li>
                    <li>
                      <Link to="/integration-deployment">
                        Integrations Deployment
                      </Link>
                    </li>
                    <li>
                      <Link to="/client-side-deployment">
                        Client Side Deployment
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              {/* <!--Support--> */}

              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 4 }}
              >
                <div className="links">
                  <h4>Support</h4>
                  <ul>
                    <li>
                      <Link to="/disclaimer">Disclaimer</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/webadminlogin">Web Admin Login</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    {/* <li><Link to="/faq">FAQ</Link></li> */}
                  </ul>
                </div>
              </Col>
              {/* <!--Support--> */}

              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
              >
                <div className="footer-cta">
                  <div className="cta-details">
                    <h4 className="text-white">
                      <a href="tel:+41617178787">+41 61 717 87 87</a>
                    </h4>
                    <p>Mon - Fri 9Am - 6Pm</p>
                  </div>
                  <div className="cta-details">
                    <h4 className="text-white">
                      <a href="tel:+41617178788">+41 61 717 87 88</a>
                    </h4>
                    <p>Mon - Fri 9Am - 6Pm</p>
                  </div>
                  <div className="cta-details">
                    <h4 className="text-white">
                      <a href="mailto:info@documentit.com">
                        info@documentit.com
                      </a>
                    </h4>
                    <p>Online Support</p>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="bottom-footer text-white">
              <div className="copyright-text">
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <div className="social-icon">
                      <a
                        href="https://www.linkedin.com/company/document-it-llc/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-linkedin" />
                      </a>
                      <a
                        href="https://www.facebook.com/pages/document-it-llc/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </a>
                      <a
                        href="https://twitter.com/document-it-llc/"
                        target="_balnk"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </a>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <p className="text-white mb-0">
                      Copyright © 2024{" "}
                      <a href="https://www.documentit.com">Document IT</a>. All
                      Rights Reserved{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>

        <BackTop>
          <div style={style}>
            <i className="fa fa-chevron-up" />
          </div>
        </BackTop>
      </React.Fragment>
    );
  }
}

export default Footer;
