import React from "react";
import renderHTML from "react-render-html";
import { Link } from "react-router";
import { connect } from "react-redux";
import CheckBoxTable from "./checkboxtable";
import {
  fetchallblogs,
  deleteblog,
  editblog,
  updateblog,
} from "../actions/blogactions";
import SimpleModal from "../Screens/Modals/Simplemodal";
import Blogs from "./Blogs";

const underscoreId = "_id";
class Allblogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      selectAll: false,
      openFaqPopup: false,
      showupdatebutton: false,
      filterable: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchallblogs());
  }
  toggleSelection = (selection) => {
    this.setState({ selection });
  };
  closeFaqPopup = () => {
    this.setState({ openFaqPopup: false });
  };
  deleteblog = (id) => {
    const { dispatch } = this.props;
    dispatch(deleteblog(id)).then((response) => {
      alert("Deleted blog successfully");
    });
    this.setState({ selection: [] });
  };
  editblog = (id) => {
    const { dispatch, blogform } = this.props;
    dispatch(editblog(id));
    this.setState({
      openFaqPopup: true,
      showupdatebutton: true,
      id: id[underscoreId],
    });
  };

  updateblog = () => {
    const { blogform, dispatch } = this.props;
    const { id } = this.state;
    dispatch(updateblog(id, blogform)).then((response) => {
      alert("updated Blog successfully");
      if (response && response.result) {
        alert("updated Blog successfully");
      }
    });
    this.setState({
      openFaqPopup: false,
      showupdatebutton: false,
      selection: [],
    });
  };

  showfilters = () => {
    this.setState({ filterable: true });
  };
  render() {
    const { blogs } = this.props;
    const {
      selection,
      selectAll,
      // columns,
      openFaqPopup,
      showupdatebutton,
    } = this.state;

    const columns = [
      { Header: "Title", accessor: "title" },
      {
        Header: "Description",
        accessor: "description",
        Cell: (row) => (
          <div>
            {row.original.description && renderHTML(row.original.description)}
          </div>
        ),
      },
      {
        Header: "Image",
        accessor: "image",
        Cell: (row) => (
          <div>
            <img src={row.original.image} />
          </div>
        ),
      },
      { Header: "Category", accessor: "category" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: (row) => (
          <div>
            <button onClick={() => this.editblog(row.original)}>
              <i className="fas fa-pencil-alt" />
            </button>
            <button onClick={() => this.deleteblog(row.original._id)}>
              <i className="fas fa-trash-alt" />
            </button>
          </div>
        ),
      },
    ];

    return (
      <div>
        <div className="main-top-section">
          <div className="admin-bredcrum">
            <ul>
              <li>
                <a href="">Home</a>/
              </li>
              <li>
                <a href="">Blogs</a>/
              </li>
              <li className="active">
                <a href="">All Blogs</a>
              </li>
            </ul>
          </div>

          {/* search starts here */}
          <div className="admin-faq-search">
            {/* <form>
              <div className="form-fields">
                <input type="text" Placeholder="Type here to search FAQ" />
                <button>
                  <i className="fas fa-search" />
                </button>
              </div>
            </form> */}
          </div>
          {/* search ends here */}
        </div>
        <div className="admin-title">
          <h1>All Blogs</h1>
        </div>
        {/* Title ends here */}
        <div className="card-design">
          <div className="blog-table">
            <i className="fas fa-filter" onClick={this.showfilters}></i>
            <CheckBoxTable
              data={blogs}
              columns={columns}
              selection={selection}
              selectAll={selectAll}
              enableMultiSelect={true}
              filterable={this.state.filterable}
              enableSelectAll={true}
              toggleAll={(selectAll, selection) =>
                this.setState({ selectAll, selection })
              }
              toggleSelection={this.toggleSelection}
            />
            <SimpleModal
              show={openFaqPopup}
              handleClose={this.closeFaqPopup}
              bsSize="large"
              showFooter
              header={"Edit Blog"}
              showupdatebutton={this.state.showupdatebutton}
              updatefaq={this.updateblog}
            >
              <Blogs showupdatebutton />
            </SimpleModal>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  blogs: state.BlogsReducer.blogs,
  blogform: state.BlogsReducer.blogform,
});

export default connect(mapStateToProps)(Allblogs);
