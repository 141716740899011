import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col } from "antd";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import AssetPath from "../../AssetPath/AssetPath";
import { ArrowUpRight } from "feather-icons-react";
import Button from "../../Components/Button/Button";

class WhyChooseUs extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum why-choose-us",
                title: "Why Choose Us",
                description: "Why Choose the Document IT as Workday partner?",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <section className="why-choose-us spacing">
            <div className="container">
              <Row
                justify="middle"
                align="center"
                gutter={[
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                ]}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 10 }}
                  lg={{ span: 10 }}
                >
                  <img
                    src={AssetPath.images.workdayConsulting}
                    className="border-radius-20 w-100"
                    alt="Why Choose the Document IT as Workday partner?"
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 14 }}
                  lg={{ span: 14 }}
                >
                  <div className="mb-2 dotted-card-design">
                    <h3 className="h5">Unmatched Workday Expertise:</h3>
                    <ul className="mb-0 text-gray  list font-4">
                      <li>
                        A reservoir of functional and integration-certified
                        consultants.
                      </li>
                      <li>
                        Comprehensive solutions addressing both business and
                        technical aspects.
                      </li>
                      <li>Mastery across all Workday modules.</li>
                    </ul>
                  </div>

                  <div className="mb-2 dotted-card-design">
                    <h3 className="h5">
                      Depth in Functional and Technical Coverage:
                    </h3>
                    <ul className="mb-0  text-gray  font-4 list">
                      <li>
                        Ex-certified consultants proficient in all Workday
                        modules.
                        <ul className="mb-0 list">
                          <li>Workday Launch Methodology certified</li>
                          <li>Workday Project Management certified</li>
                        </ul>
                      </li>
                      <li>
                        Our consultants are deeply embedded in the Workday
                        ecosystem, with an average experience of over 5 years.
                      </li>
                    </ul>
                  </div>

                  <div className="mb-2 dotted-card-design">
                    <h3 className="h5">A Truly International Dimension:</h3>
                    <ul className="mb-0 text-gray font-4 list">
                      <li>
                        We are specialists in global projects with Workday
                        implementations across 70+ countries.
                      </li>
                      <li>
                        Presence on multiple continents, ensuring
                        round-the-clock support.
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </section>

          {/* achive-business-goal  */}
          <section className="achive-business-goal">
            <div className="container">
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <h2>
                    Achieve business goals faster with data science and AI
                  </h2>
                  <p>How we’ve helped clients increase business value…</p>
                  <Button
                    to="/contact"
                    type="primary"
                    icon={<ArrowUpRight size={15} />}
                    iconPosition="end"
                    color="primary"
                    label="Talk to an Expert"
                  />
                </Col>
              </Row>
            </div>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default WhyChooseUs;
