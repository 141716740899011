import { cloneDeep } from "lodash";
import { Blogtype } from "../actions/actionType";

const underscoreId = "_id";
const initialState = {
  blogs: [],
  blogform: {
    title: "",
    description: "",
    image: "",
    tag: "",
    category: ""
  },
  Readmoreblog: {},
  blogsbycategory: []
};



const updateblogform = (state, action) => {
  const blogs = cloneDeep(state.blogs);
  blogs.map(blog => {
    if (blog[underscoreId] === action.payload.updateblogid) {
      //eslint-disable-next-line
      (blog[underscoreId] = action.payload.updateblogid),
        (blog.title = action.payload.result.title),
        (blog.description = action.payload.result.description);
        (blog.image = action.payload.result.image);
        (blog.tag = action.payload.result.tag);
        (blog.category = action.payload.result.category);
    } else {
      return blog;
    }
  });
  console.log(blogs);
  return { ...state, blogs };
};
const setblogform = (state, action) => {
  console.log(action.payload, "action.payload");
  const form = cloneDeep(state.blogform);
    //eslint-disable-next-line
      form.title=action.payload.title ,
      form.description=action.payload.description,
      form.image =action.payload.image,
      form.tag=action.payload.tag,
      form.category=action.payload.category

  return { ...state, blogform: form };
};
const deleteblog =(state,action) => {
  const blogs = cloneDeep(state.blogs);
  const updatedblogs = [];
  blogs.map(blog => {
    if (blog[underscoreId] === action.payload[underscoreId]) {
      // do nothing
    } else {
      updatedblogs.push(blog);
    }
    return null;
  });
  return { ...state, blogs: updatedblogs };
}
const createblog = (state, action) => {
  console.log(action.payload, "action.payload");
  const blogs = cloneDeep(state.blogs);
  blogs.push(action.payload);
  return { ...state, blogs };
};

const changeblogform = (state, action) => {
  console.log(action.payload, "action.payload");
  const blogform = cloneDeep(state.blogform);
  const key = action.payload.key;
  const value = action.payload.value;
  Object.keys(blogform).map(keys => {
    if (keys === key) {
        blogform[key] = value;
    }
  });
  return { ...state, blogform };
};

export default function AdminReducer(state = initialState, action) {
  switch (action.type) {
    case Blogtype.CHANGE_BLOG_FORM: {
      return changeblogform(state, action);
    }
    case Blogtype.CREATE_BLOG: {
      return createblog(state, action);
    }
    case Blogtype.FETCH_BLOGS: {
      return {...state,blogs:action.payload }
    }
    case Blogtype.DELETE_BLOGS: {
      return deleteblog(state, action);
    }
    case Blogtype.SET_BLOG_FORM: {
      return setblogform(state, action);
    }
    case Blogtype.UPDATE_BLOG: {
      return updateblogform(state, action);
    }
    case Blogtype.EMPTY_BLOG_FORM: {
      return { ...state, blogform: action.payload };
    }
    case Blogtype.READMORE_BLOG: {
      return {...state, Readmoreblog:action.payload}
    }
    case Blogtype.FETCH_BLOG_BY_CATEGORY: {
      return {...state, blogsbycategory:action.payload}
    }
    default: {
      return state;
    }
  }
}
