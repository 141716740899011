import { cloneDeep} from 'lodash';
import { Solutionsrequestdemotype } from '../actions/actionType';

const initialState = {
   requestdemos:[],
   requestdemobysolution: []
};

const createrequestdemos  = (state, action) => {
    const requestdemocloneDeep =[...state.requestdemos]
    requestdemocloneDeep.push(action.payload)
    return {...state, requestdemos:requestdemocloneDeep }
}

export default function RequestdemoReducer(state = initialState, action) {
  switch (action.type) {
    case Solutionsrequestdemotype.CREATE_REQUEST_DEMO: {
      return createrequestdemos(state, action)
    }
    case Solutionsrequestdemotype.FETCH_REQUEST_DEMO_BY_SOLUTION: {
        return {...state, requestdemobysolution:action.payload}
    }
    default: {
      return state;
    }
  }
}
