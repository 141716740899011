import  React from 'react';
import { connect } from "react-redux";
import Sidemenubar from '../Screens/Sidemenubar/sidemenubar'
import  Navbar  from '../Screens/Navbar/Navbar';
import Blogs from './Blogs';
import AllFaqs from './AllFaqs';
import ErrorPage from './Errorpage';
// import { connect } from 'react-redux';

class webAdminPage extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    render(){
        const { location } = this.props;
        const admins = localStorage.getItem('admins')
        console.log(admins, 'adminadminadmin')
        return (
            <div className="main-admin-pannel">
                {(location.pathname === '/') ? '': (
                    admins && admins.length>0 ?
                    <React.Fragment>
                    <div className="top-nav-bar">
                    <Navbar />
                    </div>
                    <div className="side-menu-bar">
                    <Sidemenubar />
                   </div>
                    </React.Fragment>
                   : <ErrorPage router={this.props.router} />
                )}
                {(location.pathname === '/') ? this.props.children: admins &&  admins.length>0 ?
                <div className="admin-center-container">
                     {this.props.children}
                </div>
                : <ErrorPage router={this.props.router} />
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    admins: state.AdminReducer.admins
  });
  
  export default connect(mapStateToProps)(webAdminPage);