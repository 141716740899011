import { API_POST } from "./APIMethods";
import { Admintype } from "./actionType";
import { AdminUrl } from "./actionURL";

export const changeadminloginform = obj => dispatch =>
dispatch({ 
  type: Admintype.CHANGE_ADMIN_FORM,
  payload: obj
});

export const validateadmin = data => {
  const url = `${AdminUrl.VALIDATE_ADMIN}`;
  return async dispatch => {
    try {
      const result = await API_POST(url, data);
      console.log(result, 'result')
      localStorage.setItem('admins', result.result);
      dispatch({ 
        type: Admintype.SET_ADMIN,
        payload: result.result
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };
};


export const createadmin = data => {
  const url = `${AdminUrl.CREATE_ADMIN}`;
  return async dispatch => {
    try {
      const result = await API_POST(url, data);
      console.log(result, 'result')
      localStorage.setItem('admins', result.result);
      dispatch({ 
        type: Admintype.CREATE_ADMIN,
        payload: result.result
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };
};
