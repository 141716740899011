/* eslint-disable */
import React, { Component } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import './SimpleModal.scss';

class SimpleModal extends Component {
  render() {
    return (
      <Modal
        {...this.props}
        // bsSize="large"
        dialogClassName="custom-modal"
        aria-labelledby="contained-modal-title-lg"
        className = "Modal-popup"
      >
        <Modal.Header closeButton>
          <Row>
            <Col lg={10} md={10} sm={10} className="no-padding">
              <Modal.Title id="contained-modal-title-lg">
                {this.props.header}
              </Modal.Title>
            </Col>
            <Col lg={2} md={2} sm={2} className="no-padding">
              <div onClick={this.props.handleClose} className="close-button">
                <i className="fa fa-times" />
              </div>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>{this.props.children}</Modal.Body>
        {this.props.showupdatebutton ? (
          <Modal.Footer>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="action-wrap text-center"
              >
                <Button
                  onClick={this.props.updatefaq}
                  className="no-margin"
                  style={{ padding: '5px 20px' }}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="action-wrap text-center"
              >
                <Button
                  onClick={this.props.handlesubmit}
                  className="no-margin"
                  style={{ padding: '5px 20px' }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export default SimpleModal;
