import { API_POST, API_GET } from "./APIMethods";
import { contactusUrl } from "./actionURL";

export const createContactus = async data => {
    const url = `${contactusUrl.CREATE_CONTACTUS}`;
        try {
            const result = await API_POST(url, data);
            return result
        } catch (error) {
            console.log(error);
        }
};

export const getContactus = async (obj) => {
    const url = `${contactusUrl.FETCH_CONTACTUS}`;
    try {
        const result = await API_POST(url, obj);
        return result
    } catch (error) {
        console.log(error);
    }
};