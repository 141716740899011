import React from "react";
import { connect } from "react-redux";
import { changeadminloginform, createadmin } from '../actions/adminloginactions'

class adminlogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  changefield = (obj) => {
    console.log(obj, 'obj');
    const {dispatch} = this.props;
    dispatch(changeadminloginform(obj))
  }


  createAdmin = () => {
    const {dispatch, admin} = this.props;
    dispatch(createadmin(admin))
    .then(response => {
      if (!response.error) {
        if(response && response.result){
          this.props.router.push('/addblog')
        }
      } else{
        alert(response.message)
      }
    })
  }

  render() {
    return (
      <div>
        <label>userName</label>
        <input type="text" onChange = {(e) =>this.changefield({key:"username" , value: e.target.value})}/><br/>
        <label>Password</label>
        <input type="text" onChange = {(e) =>this.changefield({key:"password" , value: e.target.value})}/><br/>
        <button onClick ={this.createAdmin}>Submit</button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  admin : state.AdminReducer.adminform
});

export default connect(mapStateToProps)(adminlogin);
