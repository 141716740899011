import AppConfig from "../config";

export const spmUrl = {
  CREATE_USERS: `${AppConfig.BASE_URL}spm/registeruser`
};

export const TasUrl = {
  CREATE_USERS: `${AppConfig.BASE_URL}tas/registeruser`
};

export const AdminUrl = {
  VALIDATE_ADMIN: `${AppConfig.BASE_URL}webadmin/adminlogin`,
  CREATE_ADMIN: `${AppConfig.BASE_URL}webadmin/createadmin`
};

export const BlogUrl = {
  CREATE_BLOG: `${AppConfig.BASE_URL}blogs/createblog`,
  FETCH_BLOGS: `${AppConfig.BASE_URL}blogs/allblogs`,
  DELETE_BLOGS: `${AppConfig.BASE_URL}blogs/deleteblogs`,
  UPDATE_BLOG: `${AppConfig.BASE_URL}blogs/editblog`,
  FETCH_BLOG_BY_CATEGORY: `${AppConfig.BASE_URL}blogs/fetchblogsbycategory`
};

export const FaqUrl = {
  CREATE_FAQ: `${AppConfig.BASE_URL}faq/createfaq`,
  FETCH_ALLFAQS: `${AppConfig.BASE_URL}faq/allfaqs`,
  UPDATE_FAQ: `${AppConfig.BASE_URL}faq/editfaq`,
  DELETE_FAQ: `${AppConfig.BASE_URL}faq/deletefaq`
};

export const RequestdemoUrl = {
  CREATE_REQUEST_DEMO: `${AppConfig.BASE_URL}requestdemo/createrequestdemo`,
  FETCH_REQUEST_DEMO_BY_PRODUCT: `${AppConfig.BASE_URL}requestdemo/requestdemosforproduct`
};

export const contactusUrl = {
  CREATE_CONTACTUS: `${AppConfig.BASE_URL}contactus/createcontactus`,
  FETCH_CONTACTUS: `${AppConfig.BASE_URL}contactus/getcontactus`
};

export const SolutionsrequestdemoUrl = {
  CREATE_REQUEST_DEMO: `${AppConfig.BASE_URL}solutionsrequestdemo/createrequestdemo`,
  FETCH_REQUEST_DEMO_BY_SOLUTION: `${AppConfig.BASE_URL}solutionsrequestdemo/requestdemosforsolutions`
};
