import React, { Component } from "react";
import { Link } from "react-router";
import { Row, Col } from "antd";

class Breadcrumbs extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.navigatons.map((val) => {
          return (
            <div className={val.classname}>
              <div className="breadcrum-content">
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 22 }}
                    sm={{ span: 22 }}
                    md={{ span: 22 }}
                    lg={{ span: 22 }}
                  >
                    <h1>{val.title}</h1>
                    {val.description && <p>{val.description}</p>}
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default Breadcrumbs;
