import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col } from "antd";
import datamigration from "../../assets/images/services/data-migration.jpg";
import integrationbuild from "../../assets/images/services/integration-build.jpg";
import testing from "../../assets/images/services/testing.jpg";
import dataextratcion from "../../assets/images/services/data-extraction.jpg";
import bigData from "../../assets/images/services/big-data.jpg";
import healthcareImage from "../../assets/images/services/health-care.jpg";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import Button from "../../Components/Button/Button";
import { ArrowUpRight } from "feather-icons-react";

class Career extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname: "breadcrum career",
                title: "Career",
                description: "Think outside the box evolving your career.",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <div className="career-job-list">
            <Row justify="center">
              <Col span={20}>
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <div className="career-list">
                      <div className="list">
                        <div className="details">
                          <h4>
                            Senior Workday Integrations solutions architect{" "}
                          </h4>
                          <p>
                            We are looking for Senior Workday Integrations
                            solutions architect to work on the projects.
                          </p>
                        </div>
                      </div>
                      {/* <!--List ends--> */}

                      <div className="list">
                        <div className="details">
                          <h4>Senior Workday Integrations experts </h4>
                          <p>
                            We are looking for Senior Workday Integrations
                            experts to work on the projects in Banking industry.
                          </p>
                        </div>
                      </div>
                      {/* <!--List ends--> */}
                      <div className="list">
                        <div className="details">
                          <h4>Senior Workday Functional experts </h4>
                          <p>
                            We are looking for Senior Workday Functional experts
                            to work on the projects in Banking industry.
                          </p>
                        </div>
                      </div>
                      {/* <!--List ends--> */}

                      <div className="list">
                        <div className="details">
                          <h4>Senior Workday Business Analysts</h4>
                          <p>
                            We are looking for Senior Workday Business Analysts
                            to work on the projects in Banking industry.
                          </p>
                        </div>
                      </div>
                      {/* <!--List ends--> */}

                      <div className="list">
                        <div className="details">
                          <h4>Senior workday Testing experts</h4>
                          <p>
                            We are looking for Senior workday Testing experts to
                            work on the projects in Banking industry.
                          </p>
                        </div>
                      </div>
                      {/* <!--List ends--> */}

                      <div className="list">
                        <div className="details">
                          <h4>Senior Workday Data migration experts</h4>
                          <p>
                            We are looking for Senior Workday Data migration
                            experts to work on the projects in Banking industry.
                          </p>
                        </div>
                      </div>
                      {/* <!--List ends--> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          {/* achive-business-goal  */}
          <section className="achive-business-goal">
            <div className="container">
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <h2>
                    Join Our Team: Exciting Opportunities for MBA & MSW HR
                    Graduates!
                  </h2>
                  <p>
                    Kickstart your career with us! Apply now for dynamic
                    training roles designed for future HR leaders. Share your
                    resume at{" "}
                    <a href="hr@documentit.com" target="_blank">
                      hr@documentit.com
                    </a>
                    .
                  </p>
                </Col>
              </Row>
            </div>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default Career;
