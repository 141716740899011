import { API_POST, API_GET } from "./APIMethods";
import { Requestdemotype } from "./actionType";
import { RequestdemoUrl } from "./actionURL";



export const createrequestdemo = data => {
  const url = `${RequestdemoUrl.CREATE_REQUEST_DEMO}`;
  return async dispatch => {
    try {
      const result = await API_POST(url, data);
      console.log(result, "result");
      dispatch({
        type: Requestdemotype.CREATE_REQUEST_DEMO,
        payload: result.result
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };
};

export const requestdemobyproduct = (obj) => {
  const url = `${RequestdemoUrl.FETCH_REQUEST_DEMO_BY_PRODUCT}`;
  return async dispatch => {
    try {
      const result = await API_POST(url, obj);
      console.log(result, "result");
      dispatch({
        type: Requestdemotype.FETCH_REQUEST_DEMO_BY_PRODUCT,
        payload: result.result
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const changefield = obj => dispatch =>
  dispatch({
    type: Requestdemotype.CHANGE_REQUEST_DEMO_FORM,
    payload: obj
  });

export const emptyfaqform = obj => dispatch =>
  dispatch({
    type: Requestdemotype.EMPTY_REQUEST_DEMO_FORM,
    payload: obj
  });


