import React, { Component } from "react";
import { Link, withRouter } from "react-router";
import Slider from "react-slick";
import "./HomeSlider.scss";
import { Row, Col } from "antd";
import Button from "../../../Components/Button/Button";
import { ArrowUpRight } from "feather-icons-react";

const Mainslidersettings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  fade: true,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 700,
      settings: {
        arrows: false,
      },
    },
  ],
};

class HomeSlider extends React.Component {
  render() {
    // const history = useHistory();
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="documentit-banner">
          <div className="documentit-banner-center">
            <div className="slider-content">
              {/* <!--Slider Item starts--> */}
              <Slider {...Mainslidersettings}>
                <div className="item workday">
                  <Row justify="center">
                    <Col span={20}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <span>Document IT</span> */}
                            <h2>Workday HCM Consulting</h2>
                            <ul className="text-gray">
                              <li>Data migrations, Integrations</li>
                              <li>Steady state support and operations</li>
                              <li>Project roll outs for new Countries</li>
                            </ul>
                            <div className="d-flex">
                              <Button
                                label="Contact Us"
                                className="me-2"
                                href="/#/contact"
                                // onClick={() => history.push("/contact")}
                                type="primary"
                              />
                              <Button
                                type="primary"
                                ghost
                                href="/#/about"
                                // onClick={() => history.push("/about")}
                                label="Know More"
                                icon={<ArrowUpRight size={16} />}
                                iconPosition="end"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item sap-to-workday">
                  <Row justify="center">
                    <Col span={20}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <span>Document IT</span> */}
                            <h2>SAP to Workday</h2>
                            <ul className="text-gray">
                              <li>
                                Several years of experience in SAP and Workday
                                Systems
                              </li>
                              <li>
                                Expertise in migration from SAP to Workday
                              </li>
                              <li>
                                In Depth of Knowledge of major 3rd party
                                integrations
                              </li>
                              <li>
                                Payroll interfaces with major leaders like ADP,
                                SD Worx
                              </li>
                            </ul>
                            <div className="d-flex">
                              <Button
                                label="Contact Us"
                                className="me-2"
                                href="/#/contact"
                                // onClick={() => history.push("/contact")}
                                type="primary"
                              />
                              <Button
                                type="primary"
                                ghost
                                href="/#/about"
                                // onClick={() => history.push("/about")}
                                label="Know More"
                                icon={<ArrowUpRight size={16} />}
                                iconPosition="end"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item data-migration">
                  <Row justify="center">
                    <Col span={20}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                            {/* <span>Document IT</span> */}
                            <h2>Expertise in data migration for Workday</h2>
                            <ul className="text-gray">
                              <li>10 millions of records</li>
                              <li>Master Data Management</li>
                              <li>Data Extraction, and Transformation</li>
                              <li>Data Migration</li>
                              <li>Automated Testing</li>
                              <li>Integration Design and Build</li>
                            </ul>
                            <div className="d-flex">
                              <Button
                                label="Contact Us"
                                className="me-2"
                                href="/#/contact"
                                // onClick={() => history.push("/contact")}
                                type="primary"
                              />
                              <Button
                                type="primary"
                                ghost
                                href="/#/about"
                                // onClick={() => history.push("/about")}
                                label="Know More"
                                icon={<ArrowUpRight size={16} />}
                                iconPosition="end"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
              </Slider>
            </div>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
