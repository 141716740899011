import React, { Component } from "react";
import { Link } from "react-router";
import { Row, Col } from "antd";
import "./JobTitle.scss";

class JobTitle extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.jobdetails.map((val) => {
          return (
            <div className="job-banner">
              <div className="job-content">
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 22 }}
                    sm={{ span: 22 }}
                    md={{ span: 22 }}
                    lg={{ span: 22 }}
                  >
                    <h1>{val.title}</h1>
                    <div className="location-other-details">
                      <p>
                        <strong>Location:</strong> {val.location}
                      </p>
                      <p>
                        <strong>Salary:</strong> {val.salary}
                      </p>
                      <a
                        href="mailto:aline.smith@mcbitss.com"
                        className="btn btn-primary"
                      >
                        Apply Job{" "}
                        <i className="flaticon-arrow-pointing-to-right" />
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default JobTitle;
