import React from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { createrequestdemo } from "../../actions/requestdemoactions";
import Requestdemoform from "../Requestdemoform";
import { Image, Affix, Row, Col } from "antd";
import Layout from "../Layout";
import "./AksaStudio.scss";
import lineleft from "../../assets/images/line_left.png";
import lineright from "../../assets/images/line_right.png";
import Button from "../../Components/Button/Button";
import { ArrowUpRight } from "feather-icons-react";

class AksaStudio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openform: false,
      name: "",
      email: "",
      mobile: "",
    };
  }

  openpopup = () => {
    this.setState({ openform: true });
  };

  closeform = () => {
    this.setState({ openform: false });
  };

  getdata = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  submitform = () => {
    const { name, email, mobile } = this.state;
    let obj = {};
    obj.name = name;
    obj.email = email;
    obj.mobile = mobile;
    obj.product = "Aksa Studio";
    this.props.dispatch(createrequestdemo(obj));
    this.setState({ openform: false });
  };
  render() {
    const { openform } = this.state;
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Document IT Products starts--> */}
          <div className="documentit-products aksa-studio-product">
            <div className="product-details">
              <Row justify="center">
                <Col span={20}>
                  <Row justify="center" gutter={16}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <h1>Aksa Studio</h1>
                      <p>
                        Aksa studio is an end-to-end solution which expands the
                        breadth, depth, quality, and consistency of your testing
                        with fewer people and in less time. Every customer has a
                        unique configuration and surprises can sometimes arise
                        after each release or patch update.{" "}
                        <strong>
                          Execute end-to-end test scenarios and complex security
                          validations
                        </strong>
                        . Safeguard personal data and the integrity of process
                        controls by checking that users can’t see or do anything
                        they shouldn’t, right down to ‘related actions’ and
                        field level permissions.
                      </p>
                      {/* <a href={require("../../assets/documents/aksa-studio.pdf")} target="_blank" className="btn btn-primary">Download Brochure </a> */}
                      <a onClick={this.openpopup} className="btn">
                        Request Demo
                      </a>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <p>
                        <span>Gamut of Workday HR Applications.</span>
                      </p>
                      <video autoplay="" muted loop id="myVideo">
                        <source
                          src={require("../../assets/images/features/aksa/aksa-studio.mp4")}
                          type="video/mp4"
                        />
                      </video>
                      {/* <img src={require("../../assets/images/products-inner/aksa.svg")} alt="dc3" /> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          {/* <!--Document IT Products starts--> */}

          {/* key benefits starts here*/}
          <div className="benefits-section">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 20 }}
              >
                <div className="heading">
                  <h2>
                    <span>Aksa Studio</span> Key benefits
                  </h2>
                </div>
              </Col>
            </Row>
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 20 }}
              >
                <Row gutter={(24, 20)} className="details margin">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <h3>Testing and Automation</h3>
                    <ul>
                      <li>
                        RPA (bots can be created on TAS and routine tasks can be
                        automated)
                      </li>
                      <li>Support multiple modules</li>
                      <li>Powerful scheduler</li>
                      <li>End-to-end testing</li>
                      <li>New releases and patch update testing</li>
                      <li>Regression testing</li>
                      <li>Integrations and security testing</li>
                      <li>
                        Video recording (can be used for end user training)
                      </li>
                      <li>
                        Pre-built test scenarios for frequently used Business
                        Process for Workday HCM
                      </li>
                      <li>Easy to configure test scenarios</li>
                      <li>
                        Change log for all test scenarios and test executions
                      </li>
                      <li>Executive overview Dashboard</li>
                      <li>Organized Logs and exports</li>
                      <li>Automated overall emails for status and failures</li>
                      <li>Support Cross browser testing</li>
                      <li>Screenshots</li>
                    </ul>

                    {/* testing automation ends */}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <img
                      src={require("../../assets/images/aksa-studio/automated-testing.jpg")}
                      alt=""
                    />
                  </Col>
                </Row>

                <Row gutter={(24, 20)}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <div className="details ">
                      <h3>Separations tracking and reorg modelling</h3>
                      <ul>
                        <li>
                          Simplifies the separation and reorg process with
                          flexible and intuitive drag-and-drop org chart
                          interface
                        </li>
                        <li>
                          All stakeholders (HR Partners/ Coordinators / Managers
                          etc.) can take their snapshots of their organizations
                          and sync it with Workday on near real time
                        </li>
                        <li>
                          Users can share and collaborate on a version merging
                          their changes together before finalizing the new org
                          structure.
                        </li>
                        <li>
                          Chat feature allows communication amongst the
                          stakeholders
                        </li>
                        <li>
                          In-built audit logs of all changes (who, what, when)
                        </li>
                        <li>
                          Real time sync with Workday of all relevant staffing
                          events (New hires, terminations, organization changes
                          etc.)
                        </li>
                        <li>
                          Ability to configure approval process as per the
                          client requirements
                        </li>
                        <li>
                          Flexibility to update the changes automatically in
                          Workday either through Workday APIs and business
                          process (termination, reorganization) or export the
                          changes in an EIB format once the changes are
                          finalized
                        </li>
                        <li>
                          Understands the Workday security model and applies the
                          same level of access on the organizations in the tool
                        </li>
                        <li>
                          The same tool can be used for separation process as
                          well as mass reorganizations
                        </li>
                        <li>
                          In built Analytics for reporting and dashboarding
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <div className="details margin">
                      <h3>File management</h3>
                      <ul>
                        <li>
                          Automate release of various forms like offer letter,
                          promotions and others
                        </li>
                        <li>Easy to use template creation</li>
                        <li>OCR to perform actions based on file content</li>
                      </ul>
                    </div>
                    <div className="details margin">
                      <h3>User Access Management</h3>
                      <ul>
                        <li>
                          One-stop solution to automate managing user access for
                          all HRIS systems
                        </li>
                      </ul>
                    </div>
                    <div className="details">
                      <h3>File management</h3>
                      <ul>
                        <li>
                          Collects data from SAP HCM, cleanse with approval
                          workflow and consolidates the data to preconfigured
                          Workday templates
                        </li>
                        <li>
                          Preconfigured with Workday advanced load and EIB
                          templates
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          {/* key benefits ends here */}

          {/* features-sliders starts*/}
          <div className="features-sliders gray-bg">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 20 }}
              >
                <div className="heading">
                  <h2>
                    <span>Aksa Studio</span> Key Features
                  </h2>
                </div>

                {/* Slider section starts */}
                <div className="features-slide">
                  <div className="list">
                    <div className="left-cont">
                      <h3>
                        Multi <span>Applications</span> and Modules
                      </h3>
                      <ul>
                        <li>
                          Preconfigured Workday test scenarios with an ability
                          to onboard downstream applications.
                        </li>
                        <li>Configurable modules inside each application</li>
                        <li>Release upgrade testing</li>
                        <li>Patch update testing</li>
                      </ul>
                    </div>
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/aksa/workday.jpg")}
                        alt="Multi Applications and Modules"
                      />
                    </div>
                  </div>
                  {/* List ends here */}

                  <div className="seperations-space">
                    <img src={lineright} alt="Aksa Studio" />
                  </div>

                  <div className="list">
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/aksa/cross-browser-execution.jpg")}
                        alt="Cross Browser Execution"
                      />
                    </div>

                    <div className="left-cont">
                      <h3>
                        Cross <span>Browser</span> Execution
                      </h3>
                      <p>
                        User can configure browser to execute test scripts in
                        backend
                      </p>
                    </div>
                  </div>
                  {/* List ends here */}

                  <div className="seperations-space">
                    <Image src={lineleft} alt="Aksa Studio" />
                  </div>

                  <div className="list">
                    <div className="left-cont">
                      <h3>
                        Drilldown <span>Dashboard</span> Summary Charts
                      </h3>
                      <ul>
                        <li>Management level testing overview</li>
                        <li>Individual User level charts</li>
                      </ul>
                      <p>Further drilldown available for:</p>
                      <ul>
                        <li>Application level dashboards and KPIs</li>
                        <li>Test script level dashboards and KPIs</li>
                      </ul>
                    </div>
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/aksa/drill-down-chart.jpg")}
                        alt="Drilldown Dashboard Summary Charts"
                      />
                    </div>
                  </div>
                  {/* List ends here */}
                  <div className="seperations-space">
                    <img src={lineright} alt="Aksa Studio" />
                  </div>

                  <div className="list">
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/aksa/data-security.jpg")}
                        alt="Data Security"
                      />
                    </div>
                    <div className="left-cont">
                      <h3>
                        Data <span>Security</span>
                      </h3>
                      <ul>
                        <li>User Management</li>
                        <li>Roles and Access</li>
                        <li>
                          Configuring Roles and Access as per Application level
                          configuration
                        </li>
                        <li>Hide and Show of data fields</li>
                      </ul>
                    </div>
                  </div>
                  {/* List ends here */}

                  <div className="seperations-space">
                    <Image src={lineleft} alt="Aksa Studio" />
                  </div>

                  <div className="list">
                    <div className="left-cont">
                      <h3>
                        <span>People & Cost</span> Modeler
                      </h3>
                      <ul>
                        <li>
                          Org modelling tool with drag & drop based calculations
                        </li>
                        <li>
                          Useful when divesting divisions or mergers and
                          acquisitions
                        </li>
                        <li>Used for remodelling existing org structure</li>
                        <li>
                          Flat file data source and can be integrated with
                          existing HRIS applications
                        </li>
                      </ul>
                    </div>
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/aksa/org-chart.jpg")}
                        alt="People & Cost Modeler"
                      />
                    </div>
                  </div>
                  {/* List ends here */}
                  <div className="seperations-space">
                    <img src={lineright} alt="Aksa Studio" />
                  </div>

                  <div className="list">
                    <div className="right-cont">
                      <Image
                        src={require("../../assets/images/features/aksa/user-access-management.jpg")}
                        alt="User access Management"
                      />
                    </div>
                    <div className="left-cont">
                      <h3>
                        User <span>access</span> Management
                      </h3>
                      <p>
                        Reading roles from Workday and automatically sending the
                        roles to downstream systems
                      </p>
                    </div>
                  </div>
                  {/* List ends here */}
                </div>
                {/* slider section ends here */}
              </Col>
            </Row>
          </div>
          {/* features-sliders ends */}

          <div className="aksa-studio-features">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 16 }}
                lg={{ span: 16 }}
              >
                <div className="heading">
                  <h2>
                    Automate ERP Systems and Saas Applications testing with our
                    Aksa Studio
                  </h2>
                </div>

                <div className="module-list">
                  <Row
                    justify="center"
                    gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                  >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <img
                        src={require("../../assets/images/aksa-studio/Robotic-Arm.jpg")}
                        alt="Robotic Arm"
                      />
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <h3>Robotic Arm</h3>
                      <ul>
                        <li>
                          RPA (bots can be created on Aksa and routine Aksaks
                          can be automated)
                        </li>
                        <li>Support multiple modules</li>
                        <li>Powerful scheduler</li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                {/* row ends */}

                <div className="module-list">
                  <Row
                    justify="center"
                    gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                  >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12, push: 12 }}
                      lg={{ span: 12, push: 12 }}
                    >
                      <img
                        src={require("../../assets/images/aksa-studio/Testing-Automation.jpg")}
                        alt="Testing Automation"
                      />
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12, pull: 12 }}
                      lg={{ span: 12, pull: 12 }}
                    >
                      <h3>Testing Automation</h3>
                      <ul>
                        <li>End-to-end testing</li>
                        <li>New releases and patch update testing</li>
                        <li>Regression testing</li>
                        <li>
                          Video recording (can be used for end user training)
                        </li>
                        <li>Pre-built test scenarios </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                {/* list ends here */}

                <div className="module-list">
                  <Row
                    justify="center"
                    gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                  >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <img
                        src={require("../../assets/images/aksa-studio/access-management.jpg")}
                        alt="Intelligent Access Management"
                      />
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <h3>Intelligent Access Management</h3>
                      <ul>
                        <li>
                          One-stop solution to automate managing user access for
                          all HRIS systems
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                {/* row ends */}

                <div className="module-list">
                  <Row
                    justify="center"
                    gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                  >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12, push: 12 }}
                      lg={{ span: 12, push: 12 }}
                    >
                      <img
                        src={require("../../assets/images/aksa-studio/push-and-pull.jpg")}
                        alt="Push & Pull - Hub"
                      />
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12, pull: 12 }}
                      lg={{ span: 12, pull: 12 }}
                    >
                      <h3>Push & Pull - Hub </h3>
                      <ul>
                        <li>
                          Hub for all HRIS applications (peoplesoft, downstream
                          systems, legacy systems)
                        </li>
                        <li>
                          Integrations can be built between different
                          applications{" "}
                        </li>
                        <li>
                          Aksa does not have to persist data. It can just pass
                          through data from one system to other.
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                {/* list ends here */}

                <div className="module-list">
                  <Row
                    justify="center"
                    gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                  >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <img
                        src={require("../../assets/images/aksa-studio/files-management.jpg")}
                        alt="File management"
                      />
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <h3>File management</h3>
                      <ul>
                        <li>
                          Automate release of various forms like offer letter,
                          promotions and others
                        </li>
                        <li>Easy to use template creation</li>
                        <li>OCR to perform actions based on file content</li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                {/* row ends */}

                <div className="module-list">
                  <Row
                    justify="center"
                    gutter={{ xs: 10, sm: 16, md: 20, lg: 20 }}
                  >
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12, push: 12 }}
                      lg={{ span: 12, push: 12 }}
                    >
                      <img
                        src={require("../../assets/images/aksa-studio/people-cost-modular.jpg")}
                        alt="People & Cost Modeller"
                      />
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12, pull: 12 }}
                      lg={{ span: 12, pull: 12 }}
                    >
                      <h3>People & Cost Modeller</h3>
                      <ul>
                        <li>
                          Reorg modellling with flexible and intuitive drag and
                          drop user interface to redesign organisation
                          structures
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                {/* list ends here */}
              </Col>
            </Row>
          </div>

          {/* <!--Fun Facts section starts--> */}
          <div className="fun-facts gray-bg circle-shape-gray">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div className="heading">
                  <h2>Products Facts and Figures</h2>
                </div>
                <Row justify="center" gutter={20}>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                    <div className="list">
                      <h3 className="text-primary">4</h3>
                      <p className="mb-0">Customers</p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                    <div className="list">
                      <h3 className="text-primary">3</h3>
                      <p className="mb-0">ERP Integration</p>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                  >
                    <div className="list">
                      <h3 className="text-primary">20k+</h3>
                      <p className="mb-0">Test Cases</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {/* //  <!--Fun Facts section ends--> */}

          {/* for more information starts*/}

          <div className="for-more-information">
            <Row justify="center">
              <Col span={20}>
                <h4>
                  For more information about <span>Aksa Studio</span>
                </h4>
                <Button
                  href="/#/contact"
                  type="primary"
                  icon={<ArrowUpRight size={15} />}
                  iconPosition="end"
                  color="primary"
                  label="Contact Us"
                />
              </Col>
            </Row>
          </div>

          {/* <!--Call back popup starts here--> */}
          {openform ? (
            <Requestdemoform
              closeform={this.closeform}
              submitform={this.submitform}
              getdata={this.getdata}
            />
          ) : (
            ""
          )}
          {/* <!--Value-adds ends--> */}
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.SpmReducer.users,
});

export default connect(mapStateToProps)(AksaStudio);
