export const SpmType = {
   CREATE_USER: ' CREATE_USER'
  };

  export const TasType = {
   CREATE_USER: ' CREATE_USER'
  };

  export const  Admintype = {
   CHANGE_ADMIN_FORM: 'CHANGE_ADMIN_FORM',
   SET_ADMIN: 'SET_ADMIN',
   CREATE_ADMIN:'CREATE_ADMIN'
  }

  export const Blogtype = {
   CHANGE_BLOG_FORM: 'CHANGE_BLOG_FORM',
   CREATE_BLOG: 'CREATE_BLOG',
   FETCH_BLOGS: 'FETCH_BLOGS',
   DELETE_BLOGS: 'DELETE_BLOGS',
   SET_BLOG_FORM: 'SET_BLOG_FORM',
   UPDATE_BLOG: 'UPDATE_BLOG',
   EMPTY_BLOG_FORM: 'EMPTY_BLOG_FORM',
   READMORE_BLOG: 'READMORE_BLOG',
   FETCH_BLOG_BY_CATEGORY: 'FETCH_BLOG_BY_CATEGORY'
  }

  export const FAQtype = {
   CHANGE_FAQ_FORM: 'CHANGE_FAQ_FORM',
   CREATE_FAQ: 'CREATE_FAQ',
   FETCH_ALLFAQS: 'FETCH_ALLFAQS',
   EMPTY_FAQ_FORM: 'EMPTY_FAQ_FORM',
   SET_FAQ_FORM: 'SET_FAQ_FORM',
   UPDATE_FAQ: 'UPDATE_FAQ',
   DELETE_FAQ: 'DELETE_FAQ'
  }

  export const Requestdemotype = {
   CREATE_REQUEST_DEMO: 'CREATE_REQUEST_DEMO',
   FETCH_REQUEST_DEMO_BY_PRODUCT: 'FETCH_REQUEST_DEMO_BY_PRODUCT',
   EMPTY_REQUEST_DEMO_FORM: 'EMPTY_REQUEST_DEMO_FORM',
   CHANGE_REQUEST_DEMO_FORM: 'CHANGE_REQUEST_DEMO_FORM'
  }

  export const Solutionsrequestdemotype = {
   CREATE_REQUEST_DEMO: 'CREATE_REQUEST_DEMO',
   FETCH_REQUEST_DEMO_BY_SOLUTION: 'FETCH_REQUEST_DEMO_BY_SOLUTION',
   EMPTY_REQUEST_DEMO_FORM: 'EMPTY_REQUEST_DEMO_FORM',
   CHANGE_REQUEST_DEMO_FORM: 'CHANGE_REQUEST_DEMO_FORM'
  }