import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col } from "antd";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import AssetPath from "../../AssetPath/AssetPath";
import { ArrowUpRight } from "feather-icons-react";
import Button from "../../Components/Button/Button";
import ContactCTA from "../Contact/ContactCTA";

class IntegrationDeployment extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <Breadcrumbs
            {...this.props}
            navigatons={[
              {
                classname:
                  "breadcrum workday-consulting-integrations-deployment",
                title: "Integrations Deployment",
                description:
                  "Our integration experts have designed and built some of the most challenging Workday integrations.",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <section className="why-choose-us spacing">
            <div className="container">
              <Row
                justify="middle"
                align="center"
                gutter={[
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                  { xs: 0, sm: 15, md: 30, lg: 30 },
                ]}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 10 }}
                  lg={{ span: 10 }}
                >
                  <img
                    src={AssetPath.images.integrationDeployemnt}
                    className="border-radius-20 w-100"
                    alt="Integrations Deployment"
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 14 }}
                  lg={{ span: 14 }}
                >
                  <div className="mb-2">
                    <span className="text-uppercase text-primary font-4 d-block mb-2">
                      Document IT Workday Services
                    </span>
                    <h2 className="h3">Integrations Deployment</h2>
                    <p>
                      Our integration experts have designed and built some of
                      the most challenging Workday integrations. Our team has an
                      extensive experience with almost all the connectors and
                      integrations including Payroll.
                    </p>

                    <p>
                      We know payroll integrations are complex country-specific
                      projects. Local regulations, Time and Absence management
                      and local payroll providers' challenges lead to complex
                      integrations. A proper framework for integration issue
                      handling and a throughout testing is critical to avoid
                      flooding your payroll team.
                    </p>
                    <Button
                      href="/#/contact"
                      type="primary"
                      icon={<ArrowUpRight size={15} />}
                      iconPosition="end"
                      color="primary"
                      label="Learn More.."
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </section>

          <ContactCTA />

          {/* achive-business-goal  */}
          <section className="achive-business-goal">
            <div className="container">
              <Row justify="center" gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <h2>
                    The DocumentIT team has built about 200+ Integrations till
                    date that covers almost all functional area across 50+
                    countries.
                  </h2>
                  <p>How we’ve helped clients increase business value…</p>
                  <Button
                    href="/contact"
                    type="primary"
                    icon={<ArrowUpRight size={15} />}
                    iconPosition="end"
                    color="primary"
                    label="Talk to an Expert"
                  />
                </Col>
              </Row>
            </div>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default IntegrationDeployment;
