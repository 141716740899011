import { cloneDeep } from 'lodash';
import { Admintype } from '../actions/actionType';


const initialState = {
  admins:[],
  adminform: {
    username: "",
    password: ""
  }
};

const changeadminform = (state, action) => {
  console.log(action.payload, 'action.payload');
  const adminform = cloneDeep(state.adminform);
  const key = action.payload.key;
  const value = action.payload.value;
  Object.keys(adminform).map(keys => {
    if (keys === key) {
      adminform[key] = value;
    }
  });
  return { ...state, adminform };
};

const addadmin = (state,action) => {

  const adminsclone = cloneDeep(state.admins);
  const admins = [...adminsclone];
  admins.push(action.payload);
  return {...state, admins}

}
export default function AdminReducer(state = initialState, action) {
  switch (action.type) {
    case Admintype.CHANGE_ADMIN_FORM: {
      return changeadminform(state, action);
    }
    case Admintype.SET_ADMIN: {
      return{...state, admins:action.payload}
    }
    case Admintype.CREATE_ADMIN: {
      return addadmin(state, action);
    }
    default: {
      return state;
    }
  }
}
