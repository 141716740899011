import React, { Component } from "react";
import Footer from "./Footers/Footers";
import Header from "./Headers/Header";

class Layout extends Component {
  componentDidMount() {
    document.getElementById("scroll-position").scrollIntoView();
  }
  render() {
    const { children } = this.props;
    return (
      <div>
        <Header />
        <div id="scroll-position">{children}</div>
        <Footer />
      </div>
    );
  }
}

export default Layout;
