import { combineReducers } from 'redux';
// import { localeReducer as locale } from 'react-localize-redux';
import SpmReducer from './spmreducer';
import TasReducer from './tasreducer';
import AdminReducer from './adminreducer';
import BlogsReducer from './blogreducer';
import FaqReducer from './Faqreducer';
import Requestdemo from './requestdemoreducer';
import Solutionsrequestdemo from './Solutionsrequestdemoreducer';
 
export default combineReducers({
  // locale,
  SpmReducer,
  TasReducer,
  AdminReducer,
  BlogsReducer,
  FaqReducer,
  Requestdemo,
  Solutionsrequestdemo
});
