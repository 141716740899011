import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col } from "antd";
import datamigration from "../../assets/images/services/data-migration.jpg";
import integrationbuild from "../../assets/images/services/integration-build.jpg";
import testing from "../../assets/images/services/testing.jpg";
import dataextratcion from "../../assets/images/services/data-extraction.jpg";
import bigData from "../../assets/images/services/big-data.jpg";
import healthcareImage from "../../assets/images/services/health-care.jpg";
import JobTitle from "../../Components/JobTitle/JobTitle";

class ItBusinessAnalyst extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <JobTitle
            {...this.props}
            jobdetails={[
              {
                title: "IT Business Analyst (Junior and Senior)",
                location: "Zurich",
                salary: "120,000 to 140,000 CHF per annum",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <div className="job-descriptions">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 18 }}
              >
                <h2>
                  We are looking for Senior & Junior Automation Engineers to
                  work on the projects in Banking industry.
                </h2>
                <p>
                  Are you passionate about bringing innovative systems to life?
                  Do you want to work with the latest cloud technologies
                  building client focused voice based processes? Do you have the
                  drive to innovate and improve user experience while increasing
                  operations efficiency?
                </p>
                <div className="profile-data">
                  <h3>Your profile</h3>
                  <ul>
                    <li>
                      Work closely with Product Owner to ensure deliveries are
                      aligned with overall strategy{" "}
                    </li>
                    <li>
                      Translate business requirements into features and user
                      stories{" "}
                    </li>
                    <li>
                      Collaborate with technologists (e.g. IT leads, security,
                      cloud experts, development teams) to drive delivery within
                      an Agile framework{" "}
                    </li>
                    <li>
                      Work with Test Management to define test cases and support
                      testing{" "}
                    </li>
                    <li>Conduct as-is and to-be impact analysis </li>
                    <li>
                      Contribute to reduction of all security and operational
                      risks, in line with policies and standards{" "}
                    </li>
                    <li>Identify opportunities to improve processes </li>
                    <li>Support L3 to investigate production problems</li>
                    <li>
                      5+ years of experience in business analysis and
                      requirements engineering{" "}
                    </li>
                    <li>
                      Expertise in conceptual modelling and modelling techniques
                      like UML or BPMN{" "}
                    </li>
                    <li>
                      Good experience in working on JIRA for project
                      documentation & task management{" "}
                    </li>
                    <li>
                      Good understanding of user journey, UX and UI design and
                      able to define/specify usability and accessibility aspect
                      of a software solution{" "}
                    </li>
                    <li>
                      Able to translate business requirements into technical
                      requirements{" "}
                    </li>
                    <li>Excellent written and verbal communication skills </li>
                    <li>
                      Fluent in English, German would also be an advantage{" "}
                    </li>
                  </ul>
                </div>

                <h4>
                  Send your CVs to{" "}
                  <a href="mailto:aline.smith@mcbitss.com">
                    aline.smith@mcbitss.com
                  </a>
                </h4>
              </Col>
            </Row>
          </div>

          {/* achive-business-goal  */}
          <section className="achive-business-goal">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 18 }}
              >
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <h2>Make the best move to choose your career</h2>
                    <p>find more jobs which suits for you..</p>
                    <Link to="/career" className="btn btn-primary">
                      More Jobs{" "}
                      <i className="flaticon-arrow-pointing-to-right" />
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default ItBusinessAnalyst;
