import React, { Component } from "react";
import { Link } from "react-router";
import { Row, Col } from "antd";
import { ArrowUpRight } from "feather-icons-react";
import Button from "../../Components/Button/Button";

class Cta extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* achive-business-goal  */}
        <section className="achive-business-goal">
          <div className="container">
            <Row justify="center" gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h2>Achieve business goals faster with data science and AI</h2>
                <p>How we’ve helped clients increase business value…</p>
                <Button
                  to="/contact"
                  type="primary"
                  icon={<ArrowUpRight size={15} />}
                  iconPosition="end"
                  color="primary"
                  label="Talk to an Expert"
                />
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Cta;
