import React, { Component } from "react";
import { Link } from "react-router";
import Layout from "../Layout";
import { Row, Col } from "antd";
import datamigration from "../../assets/images/services/data-migration.jpg";
import integrationbuild from "../../assets/images/services/integration-build.jpg";
import testing from "../../assets/images/services/testing.jpg";
import dataextratcion from "../../assets/images/services/data-extraction.jpg";
import bigData from "../../assets/images/services/big-data.jpg";
import healthcareImage from "../../assets/images/services/health-care.jpg";
import JobTitle from "../../Components/JobTitle/JobTitle";

class FrontEndDeveloper extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          {/* <!--Main Slider section starts--> */}
          <JobTitle
            {...this.props}
            jobdetails={[
              {
                title: "Front end Developer (Junior and Senior)",
                location: "Zurich",
                salary: "120,000 to 140,000 CHF per annum",
              },
            ]}
          />
          {/* <!--Main Slider section ends--> */}

          {/* <!--our solutions starts--> */}
          <div className="job-descriptions">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 18 }}
              >
                <h2>
                  We are looking for Senior & Junior Front-end Developers with
                  specialisation in Angular JS to work on the projects in
                  Banking industry.
                </h2>
                <p>
                  An interesting position in an Agile development team
                  responsible to create top-level coding-base using Angular best
                  practices. Developing mobile banking solutions
                </p>
                <div className="profile-data">
                  <h3>Your profile</h3>
                  <ul>
                    <li>
                      Design and develop frontend solutions for mobile platforms
                    </li>
                    <li>
                      Develop and debug modules and specific features taking
                      into account the technical constraints
                    </li>
                    <li>
                      Prioritize the user experience and develop features
                      accordingly
                    </li>
                    <li>
                      Striking a balance between functional and aesthetic design
                    </li>
                    <li>Building reusable code for future use</li>
                    <li>
                      Optimizing solutions for maximum speed and scalability
                    </li>
                    <li>
                      Production and maintenance mobile application user
                      interfaces
                    </li>
                    <li>Testing for usability and fixing any bugs</li>
                    <li>At least 5 years of experience as a Developer</li>
                    <li>
                      Strong knowledge of modern JS Framework, Angular 10+
                    </li>
                    <li>Excellent knowledge in Red Hat OpenShift</li>
                    <li>
                      Strong knowledge of Typescript, JavaScript, CSS
                      Pre-processors (SCSS/SASS/LESS), RESTful services and
                      APIs, Unit tests.
                    </li>
                    <li>
                      Languages: English proficiency both written and oral
                    </li>
                  </ul>
                </div>

                <h4>
                  Send your CVs to{" "}
                  <a href="mailto:aline.smith@mcbitss.com">
                    aline.smith@mcbitss.com
                  </a>
                </h4>
              </Col>
            </Row>
          </div>

          {/* achive-business-goal  */}
          <section className="achive-business-goal">
            <Row justify="center">
              <Col
                xs={{ span: 20 }}
                sm={{ span: 20 }}
                md={{ span: 20 }}
                lg={{ span: 18 }}
              >
                <Row justify="center" gutter={16}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                  >
                    <h2>Make the best move to choose your career</h2>
                    <p>find more jobs which suits for you..</p>
                    <Link to="/career" className="btn btn-primary">
                      More Jobs{" "}
                      <i className="flaticon-arrow-pointing-to-right" />
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>
        </Layout>
      </React.Fragment>
    );
  }
}

export default FrontEndDeveloper;
